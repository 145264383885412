// File: butaks-web\src\components\products\Products.js
import { toEnglishCharacters } from '../utils/stringUtils';
import { squarePlaceholder, sp, bp1Img, bp2Img, bp3Img } from '../utils'; // Resimleri utils içinden import et

const Products = [

  // Park Spor Aletleri -------------------------------------------

  {
    id: 1,
    name: 'Modern Park Spor Aleti: Bench Press Aleti',
    slug: 'modern-park-spor-aleti-bench-press',
    images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
    rating: 4.8,
    sales: '16',
    isBestSeller: false,
    isAdvantageous: true,
    isLowPrice: true,
    isVerifiedSeller: true,
    isVideoAvailable: true,
    category: 'Park Spor Aletleri',
    normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
    description: 'Az yer kaplayan tasarimiyla evde kullanim için ideal kompakt koşu bandi.',
    productCode: 'BUTAX-MPSA-0.1',
    seoKeywords: 'park spor, bench press, diş mekan spor aleti, açik hava fitness, diş mekan fitness aleti, park spor aleti, park antrenmani',
    metaDescription: 'Ayarlanabilir Bench Press ile konforlu ve etkili bir egzersiz deneyimi yaşayin. Yüksek kaliteli malzemelerle üretilmiştir.',
    campaign: 'Lansmana Özel İndirim',
    weight: '5-60 KG',
    ageRange: '+14',
    color1: 'RAL7021',
    color2: 'RAL1037',
    color3: 'RAL5012',
    color4: 'RAL3020',
    color5: 'RAL1019',
    cargodelivery: '6',
    hedefbolge: 'Göğüs',
    length: '2730 mm',
width: '930 mm',
height: '1430 mm',
equipmentWeight: '164,2 kg (1 x ağırlık birimi), 223,4 kg (2 x ağırlık birimi)',
userHeight: '+140 cm',
safetyArea: '18,1 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama, elektropolaaj'
  },
  {
    id: 2,
    name: 'Modern Park Spor Aleti: Biceps Curl Aleti',
    slug: 'modern-park-spor-aleti-biceps-curl',
    images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
    rating: 4.7, // Farklı bir değerlendirme puanı verildi.
    sales: '16', // Satış bilgisi değiştirildi.
    isBestSeller: true, // Ürün farklı olarak çok satan olabilir.
    isAdvantageous: true,
    isLowPrice: false, // Ürünün fiyat avantajı olmadığı varsayıldı.
    isVerifiedSeller: true,
    isVideoAvailable: true,
    category: 'Park Spor Aletleri',
    normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
    description: 'Dayanıklı malzemelerden üretilmiş ve kol kaslarınızı geliştirmek için tasarlanmış kompakt biceps curl aleti.',
    productCode: 'BUTAX-MPSA-0.2',
    seoKeywords: 'park spor, biceps curl, diş mekan spor aleti, açik hava fitness, diş mekan fitness aleti, park spor aleti, park antrenmani',
    metaDescription: 'Kol kaslarınızı hedefleyen dayanıklı Biceps Curl Aleti ile etkili bir egzersiz deneyimi yaşayın. Yüksek kaliteli malzemelerle üretilmiştir.',
    campaign: 'Lansmana Özel İndirim',
    weight: '5-60 KG',
    ageRange: '+14',
    color1: 'RAL7021',
    color2: 'RAL1037',
    color3: 'RAL5012',
    color4: 'RAL3020',
    color5: 'RAL1019',
    cargodelivery: '9',
    hedefbolge: 'Biceps',
    length: '2195 mm',
width: '675 mm',
height: '1430 mm',
equipmentWeight: '143,2 kg (1 x ağırlık birimi)',
userHeight: '+140 cm',
safetyArea: '18 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama, elektropolaaj'
},
{
  id: 3,
  name: 'Modern Park Spor Aleti: Chin Up Bar Aleti',
  slug: 'modern-park-spor-aleti-chin-up-bar',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.9, // Bu ürünün yüksek bir değerlendirme puanı olduğu varsayıldı.
  sales: '16', // Satış bilgisi değiştirildi.
  isBestSeller: false, 
  isAdvantageous: true,
  isLowPrice: true,
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Çok yönlü kullanımıyla hem parkta hem evde rahatlıkla kullanılabilecek dayanıklı Chin Up Bar.',
  productCode: 'BUTAX-MPSA-0.3',
  seoKeywords: 'park spor, chin up bar, diş mekan spor aleti, açik hava fitness, diş mekan fitness aleti, barfiks çubuğu, park antrenmani',
  metaDescription: 'Chin Up Bar ile vücut ağırlığınızı kullanarak etkili bir üst vücut antrenmanı yapabilirsiniz. Yüksek kaliteli malzemelerle üretilmiştir.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Sırt ve Kol',
  length: '?',
width: '?',
height: '?',
equipmentWeight: '?',
userHeight: '+140 cm',
safetyArea: '?',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama, elektropolaaj'
},
{
  id: 4,
  name: 'Modern Park Spor Aleti: Incline Bench Press Aleti',
  slug: 'modern-park-spor-aleti-incline-bench-press',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.6, // Orta-yüksek bir değerlendirme puanı verildi.
  sales: '9', // Satış bilgisi belirlendi.
  isBestSeller: false, 
  isAdvantageous: true,
  isLowPrice: false, // Daha premium bir ürün olduğu varsayıldı.
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Göğüs kaslarınızı geliştirmek ve farklı eğimlerle antrenman yapmak için ideal Incline Bench Press aleti.',
  productCode: 'BUTAX-MPSA-0.4',
  seoKeywords: 'park spor, incline bench press, diş mekan spor aleti, açik hava fitness, göğüs kası antrenmanı, park spor aleti, park antrenmani',
  metaDescription: 'Incline Bench Press ile göğüs kaslarınızı daha etkili bir şekilde çalıştırın. Yüksek kaliteli malzemelerle üretilmiş dayanıklı tasarım.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Üst Göğüs',
  length: '2610 mm',
width: '930 mm',
height: '1430 mm',
equipmentWeight: '170,1 kg (1 x ağırlık birimi), 229,3 kg (2 x ağırlık birimi)',
userHeight: '+140 cm',
safetyArea: '17,6 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama, elektropolij'
},
{
  id: 5,
  name: 'Modern Park Spor Aleti: Lat Pull Down Aleti',
  slug: 'modern-park-spor-aleti-lat-pull-down',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.7, // Yüksek bir değerlendirme puanı.
  sales: '16', // Satış bilgisi eklendi.
  isBestSeller: true, // Ürün çok satan olarak işaretlendi.
  isAdvantageous: true,
  isLowPrice: false, // Daha premium bir ürün olduğu düşünüldü.
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Sırt ve kanat kaslarınızı hedefleyen ergonomik tasarımlı Lat Pull Down aleti, açık havada antrenman için mükemmel bir seçimdir.',
  productCode: 'BUTAX-MPSA-0.5',
  seoKeywords: 'park spor, lat pull down, diş mekan spor aleti, açik hava fitness, sırt kası antrenmanı, park spor aleti, park antrenmani',
  metaDescription: 'Lat Pull Down aleti ile sırt kaslarınızı etkili bir şekilde çalıştırın. Dayanıklı malzemeler ve konforlu tasarımı ile dikkat çeker.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Sırt',
  length: '1845 mm',
width: '1070 mm',
height: '1820 mm',
equipmentWeight: '207,7 kg (1 x ağırlık birimi), 251 kg (2 x ağırlık birimi)',
userHeight: '+140 cm',
safetyArea: '15,7 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama, elektropolij'
},
{
  id: 6,
  name: 'Modern Park Spor Aleti: Leg Press Aleti',
  slug: 'modern-park-spor-aleti-leg-press',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.8, // Üst düzey bir değerlendirme puanı.
  sales: '16', // Satış bilgisi eklendi.
  isBestSeller: false, 
  isAdvantageous: true,
  isLowPrice: false, // Fiyat avantajlı olmayan bir ürün olarak işaretlendi.
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Bacak kaslarınızı geliştirmek için mükemmel bir tercih olan Leg Press aleti, dayanıklı ve ergonomik tasarımıyla dikkat çeker.',
  productCode: 'BUTAX-MPSA-0.6',
  seoKeywords: 'park spor, leg press, diş mekan spor aleti, açik hava fitness, bacak antrenmanı, park spor aleti, park antrenmani',
  metaDescription: 'Leg Press ile bacak kaslarınızı güçlendirin ve dayanıklılığınızı artırın. Yüksek kaliteli malzemelerle üretilmiştir.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Bacak',
  length: '3120 mm',
width: '1140 mm',
height: '1770 mm',
equipmentWeight: '425 kg',
userHeight: '+140 cm',
safetyArea: '23 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama'
},
{
  id: 7,
  name: 'Modern Park Spor Aleti: Multi-Fit 3-in-1 Egzersiz Aleti',
  slug: 'modern-park-spor-aleti-multi-fit-3-in-1',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.9, // Çok yönlü olduğu için yüksek bir değerlendirme puanı.
  sales: '67', // Çok işlevli olduğu için yüksek satış miktarı.
  isBestSeller: true, // Çok satan bir ürün olarak işaretlendi.
  isAdvantageous: true,
  isLowPrice: false, // Premium bir ürün olduğu düşünüldü.
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Leg Raise, Dip ve Chin Up özelliklerini bir arada sunan bu çok yönlü alet, üst vücut ve karın kaslarını etkili bir şekilde çalıştırmak için mükemmeldir.',
  productCode: 'BUTAX-MPSA-0.7',
  seoKeywords: 'park spor, leg raise, dip, chin up, çok işlevli spor aleti, diş mekan fitness, üst vücut antrenmanı, park spor aleti, park antrenmani',
  metaDescription: 'Multi-Fit 3-in-1 Egzersiz Aleti ile leg raise, dip ve chin up hareketlerini tek bir alette gerçekleştirin. Sağlam ve ergonomik tasarımlıdır.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Karın, Arka Kol ve Bel',
  length: '1540 mm',
width: '960 mm',
height: '2335 mm',
equipmentWeight: '167 kg',
userHeight: '+140 cm',
safetyArea: '16 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama'
},
{
  id: 8,
  name: 'Modern Park Spor Aleti: Core Rack',
  slug: 'modern-park-spor-aleti-core-rack',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.8, // Üst düzey bir değerlendirme puanı.
  sales: '300', // Orta seviyede bir satış bilgisi eklendi.
  isBestSeller: false, 
  isAdvantageous: true,
  isLowPrice: true, // Daha uygun fiyatlı bir ürün olarak işaretlendi.
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Core Rack, karın, sırt ve core bölgesini hedefleyen etkili bir antrenman aracı olup açık hava kullanımına uygundur.',
  productCode: 'BUTAX-MPSA-0.8',
  seoKeywords: 'park spor, core rack, diş mekan spor aleti, açik hava fitness, core antrenmanı, park spor aleti, park antrenmani',
  metaDescription: 'Core Rack ile karın ve core bölgenizi güçlendirin. Dayanıklı ve ergonomik tasarımıyla açık hava antrenmanlarına uygundur.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Karın',
  length: '3830 mm',
width: '1135 mm',
height: '1430 mm',
equipmentWeight: '141,2 kg',
userHeight: '+140 cm',
safetyArea: '23,3 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama'
},
{
  id: 9,
  name: 'Modern Park Spor Aleti: Squat',
  slug: 'modern-park-spor-aleti-squat',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.7, // Yüksek bir değerlendirme puanı.
  sales: '16', // Popüler bir ürün için yüksek satış bilgisi.
  isBestSeller: true, // Çok satan bir ürün olarak işaretlendi.
  isAdvantageous: true,
  isLowPrice: false, // Daha premium bir ürün olarak kabul edildi.
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Squat hareketini güvenle ve etkili bir şekilde yapmanıza olanak tanıyan dayanıklı ve ergonomik tasarıma sahip bir spor aleti.',
  productCode: 'BUTAX-MPSA-0.9',
  seoKeywords: 'park spor, squat, diş mekan spor aleti, açik hava fitness, bacak kası antrenmanı, park spor aleti, park antrenmani',
  metaDescription: 'Squat spor aleti ile bacak kaslarınızı ve formunuzu güçlendirin. Sağlam yapısı ve ergonomik tasarımıyla açık hava kullanımı için idealdir.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Kalça ve Bacak',
  length: '2045 mm',
width: '930 mm',
height: '1545 mm',
equipmentWeight: '164,4 kg (1 x ağırlık birimi), 223,6 kg (2 x ağırlık birimi)',
userHeight: '+140 cm',
safetyArea: '16,4 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama, elektropolij'
},
{
  id: 10,
  name: 'Modern Park Spor Aleti: Triceps Press',
  slug: 'modern-park-spor-aleti-triceps-press',
  images: [squarePlaceholder, squarePlaceholder, squarePlaceholder],
  rating: 4.6, // İyi bir değerlendirme puanı.
  sales: '20', // Orta seviyede satış bilgisi.
  isBestSeller: false, 
  isAdvantageous: true,
  isLowPrice: true, // Uygun fiyatlı bir ürün olarak işaretlendi.
  isVerifiedSeller: true,
  isVideoAvailable: true,
  category: 'Park Spor Aletleri',
  normalizedCategory: toEnglishCharacters("Park Spor Aletleri"),
  description: 'Triceps kaslarınızı hedefleyerek kol gücünüzü artırmanıza yardımcı olan dayanıklı Triceps Press aleti.',
  productCode: 'BUTAX-MPSA-0.10',
  seoKeywords: 'park spor, triceps press, diş mekan spor aleti, açik hava fitness, kol kası antrenmanı, park spor aleti, park antrenmani',
  metaDescription: 'Triceps Press ile kol kaslarınızı şekillendirin ve güçlendirin. Ergonomik tasarımı ile konforlu bir antrenman sunar.',
  campaign: 'Lansmana Özel İndirim',
  weight: '5-60 KG',
  ageRange: '+14',
  color1: 'RAL7021',
  color2: 'RAL1037',
  color3: 'RAL5012',
  color4: 'RAL3020',
  color5: 'RAL1019',
  cargodelivery: '9',
  hedefbolge: 'Arka Kol',
  length: '2320 mm',
width: '670 mm',
height: '1970 mm',
equipmentWeight: '152,8 kg (1 x ağırlık birimi)',
userHeight: '+140 cm',
safetyArea: '17,5 m²',
baseMounting: 'Beton üzerine yüzey montajı',
standard: 'EN16630',
countryOfManufacture: 'Türkiye',
metalParts: 'Çelik',
tubeStrengths: '3-6 mm',
coatingSolution: 'Çinko ve toz boya kaplama, elektropolij'
},
];

export default Products;

