// File: butaks-web/src/pages/Urunlerimiz.jsx
import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import ProductSets from '../components/products/ProductSets';
import ProductGridWithFilter from '../components/products/ProductGridWithFilter';

const Urunlerimiz = () => {
  return (
    <div className='bg-white dark:bg-black'>
      <Helmet>
        <title>Ürünlerimiz - BUTAX | Spor ve Teknoloji</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
      </Helmet>
      <Navbar />
      <div className='flex max-w-7xl pt-6 px-4 mx-auto sm:px-6 lg:px-8'>
      <ProductGridWithFilter />
      </div>
      <Footer />
    </div>
  );
};

export default Urunlerimiz;
