import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Referanslarimiz from '../components/references/Referanslarimiz';
import { Helmet } from 'react-helmet';

const Referanslar = () => {
  return (
    <div className="bg-white dark:bg-black flex flex-col min-h-screen">
        <Helmet>
        <title>Referanslarımız - BUTAX | Spor ve Teknoloji</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
        </Helmet>
        <Navbar />
        {/* Hakkımızda sayfası içeriği */}
       <main className="flex-grow overflow-y-auto">
       <section className="nocommon-padding">
    <div className="py-20 mx-auto flex flex-wrap md:flex-row items-center justify-center">

      <div className="w-full xl:w-2/5 text-center md:text-left"> 
        <h1 className="my-4 text-3xl md:text-5xl text-black dark:text-white opacity-75 font-bold leading-tight">Referanslar sayfamız
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-pink-500 to-purple-500"> Şuan Bakımdadır </span> anlayışınız için teşekkür ederiz!
        </h1>
      </div>

    </div>
    </section>
       </main>
        {/* Hakkımızda sayfası içeriği */}
       <Footer />
    </div>
  );
};
  export default Referanslar;