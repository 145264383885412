import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { useEffect, useState } from 'react';

gsap.registerPlugin(ScrollTrigger);
const isMobile = window.matchMedia('(max-width: 768px)').matches;

// Image Slider için örnek resim verileri (kendi resimlerinizi kullanın)
const imageData = [
  { id: 1, src: '/assets/images/kasli-adam-dips-yapiyor.webp', alt: 'Slide 1' },
  { id: 2, src: '/assets/images/zarif-kadin-pilates-yapiyor.webp', alt: 'Slide 3' },
];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageData.length);
    }, 5000); // Her 5 saniyede bir slide değişsin

    return () => clearInterval(intervalId); // Component unmount olduğunda interval'i temizle
  }, [imageData.length]);

  useGSAP(() => {
    if (!isMobile) {
      gsap.fromTo('.hero-image', { width: '100%', borderRadius: 0 }, {
        width: '100%',

        scrollTrigger: {
          trigger: '.hero-image',
          start: 'top 50%',
          end: 'bottom 10%',
          scrub: true,
        },
      });
    } else {
      gsap.set('.hero-image', { width: '90%', borderRadius: '20px' });
    }
  }, [isMobile]);

  return (
    <section className="w-full bg-white dark:bg-black relative">
      <div className="flex-container items-center py-10">
        <h1 className="hero-title flex-item-left">BUTAX</h1>
        <p className="heroalt-title flex-item-right">Üst Düzey Spor Alanlarının Kurucusu.</p>
      </div>
      <div className="flex-col pb-10">
        <div className="w-full flex-center">
          <img
            src={imageData[currentImageIndex].src}
            alt={imageData[currentImageIndex].alt}
            className="hero-image"
            style={{width: '100%', height: 'auto', maxWidth: '100%'}} // Oranları koru, maksimum genişlik uygula
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;